import {
  AlertTriangleIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CommandIcon,
  CreditCardIcon,
  FileIcon,
  FileTextIcon,
  GithubIcon,
  HelpCircleIcon,
  ImageIcon,
  LaptopIcon,
  Loader2Icon,
  type LucideIcon,
  MoonIcon,
  MoreVerticalIcon,
  PizzaIcon,
  PlusIcon,
  SettingsIcon,
  SunMediumIcon,
  TrashIcon,
  TwitterIcon,
  UserIcon,
  XIcon,
} from 'lucide-react';

export type Icon = LucideIcon;

export const Icons = {
  Add: PlusIcon,
  ArrowRight: ArrowRightIcon,
  Billing: CreditCardIcon,
  ChevronLeft: ChevronLeftIcon,
  ChevronRight: ChevronRightIcon,
  Close: XIcon,
  Ellipsis: MoreVerticalIcon,
  GitHub: GithubIcon,
  Help: HelpCircleIcon,
  Laptop: LaptopIcon,
  Logo: CommandIcon,
  Media: ImageIcon,
  Moon: MoonIcon,
  Page: FileIcon,
  Pizza: PizzaIcon,
  Post: FileTextIcon,
  Settings: SettingsIcon,
  Spinner: Loader2Icon,
  Sun: SunMediumIcon,
  Trash: TrashIcon,
  Twitter: TwitterIcon,
  User: UserIcon,
  Warning: AlertTriangleIcon,
};
